import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  identityApiRef,
  storageApiRef,
  analyticsApiRef,
  useApi,
  fetchApiRef,
  discoveryApiRef
} from '@backstage/core-plugin-api';
import {
  VisitsStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';
import { ExampleCostInsightsClient, costInsightsApiRef } from '@backstage-community/plugin-cost-insights';
import { cicdStatisticsApiRef } from '@backstage-community/plugin-cicd-statistics';
import { BitbucketPipelineService } from './services/bitbucket.service';
import { BitbucketCicdStatsApi } from './cicdStats/BitbucketCicdStatsApi';
import { DurationType } from './cicdStats/types';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';
import { MultipleAnalyticsApi } from '@backstage/core-app-api';
// import { BackstageInsightsApi } from '@spotify/backstage-plugin-analytics-module-insights';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {},
    factory: () => new ExampleCostInsightsClient(),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  createApiFactory({
    api: cicdStatisticsApiRef,
    deps: { },
    factory() {
      // Config setup
      const config = useApi(configApiRef);
      const fetchApi = useApi(fetchApiRef);
      const cicdStatsConfig = config.getConfig('frontendConfig');
      const getPipelineStagesStats = cicdStatsConfig.getBoolean("getPipelineStagesStats");
      const pageLength = cicdStatsConfig.getNumber("pageLength");
      const durationType = cicdStatsConfig.getString("durationType") as DurationType;

      // Service declaration
      const bitbucketService = new BitbucketPipelineService(
        fetchApi, config
      );
      return new BitbucketCicdStatsApi({}, bitbucketService, pageLength, getPipelineStagesStats, durationType);
    },
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: analyticsApiRef,
    deps: { 
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
      storageApi: storageApiRef
    },
    factory: ({
      configApi,
      // discoveryApi,
      // fetchApi,
      identityApi,
      // storageApi
    }) => MultipleAnalyticsApi.fromApis([
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
      // BackstageInsightsApi.fromConfig(configApi, {
      //   discoveryApi,
      //   fetchApi,
      //   identityApi,
      //   storageApi,
      // })
    ])
  })
];
 