import React from 'react'
import { Icon } from "@material-ui/core";
import SonarQube from './assets/sonarqube.svg'

const SonarQubeIcon = () => (
    <Icon>
        <img src={SonarQube} height={'100%'} width={'100%'}/>
    </Icon>
)

export default SonarQubeIcon;