import { ConfigApi, FetchApi } from '@backstage/core-plugin-api';
import { MspDashboardApi } from './MspDashboardApi';

export class MspDashboardApiClient implements MspDashboardApi {
  private readonly fetchApi: FetchApi;
  private readonly configApi: ConfigApi;

  constructor(options: {
    fetchApi: FetchApi;
    configApi: ConfigApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.configApi = options.configApi;
  }

  static fromConfig(options: {
    fetchApi: FetchApi;
    configApi: ConfigApi;
  }) {
    return new MspDashboardApiClient(options);
  }

  public async getRepoDirectoryStructure(mspRepoName: string, branch: string, path: string): Promise<any> {
    // Replace the URL and headers with your actual API endpoint and authentication headers
    // const { token } = await this.identityApi.getCredentials();
    // If obfuscation is disabled, get the badge specs directly as the previous implementation
    const backendUrl = this.getBackendUrl();
    const apiUrl = `${backendUrl}/directories/${mspRepoName}/${branch}/${path}`;
    // const response = await fetch(apiUrl, {
    //   method: 'GET',
    //   headers: { Authorization: `Bearer ${token}` }
    // });
    const response = await this.fetchApi.fetch(apiUrl);

    if (!response.ok) {
      throw Error('Problem sending directories request to backend');
    }
    return await response.json();
  }

  private getBackendUrl(): string {
    const baseUrl = this.configApi.getString('backend.baseUrl');
    // The path msp-backend corresponds to the pluginId of the MSP Backend Plugin in plugins/msp-plugin-backend/plugin.ts
    return `${baseUrl}/api/mspPlugin`;
  }

  async hello(): Promise<string> {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve('world');
      }, 1000),
    );
  }
}
