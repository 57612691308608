import React from 'react'
import { Icon } from "@material-ui/core";
import StackOverflow from './assets/stackoverflow.svg'

const StackOverflowIcon = () => (
    <Icon>
        <img src={StackOverflow} height={'100%'} width={'100%'}/>
    </Icon>
)

export default StackOverflowIcon;