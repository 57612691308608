import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Announcement from '@material-ui/icons/Announcement';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import LayersIcon from '@material-ui/icons/Layers';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import BuildIcon from '@material-ui/icons/Build';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { timeSaverPermission } from '@tduniec/backstage-plugin-time-saver-common';
import Timelapse from '@material-ui/icons/Timelapse';
import { RequirePermission } from '@backstage/plugin-permission-react';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { opsgenieAccessPermission } from '../../permissions/opsgeniePermission';
import { devtoolsAccessPermission } from '../../permissions/devtoolsPermission';
import { mspAccessPermission } from '@internal/backstage-plugin-msp-common';

import { RBACSidebarItem } from '@spotify/backstage-plugin-rbac';

import TextsmsOutlined  from '@material-ui/icons/TextsmsOutlined';

// import DoneAllIcon from '@material-ui/icons/DoneAll';
// import SchoolIcon from '@material-ui/icons/School';

// import { InsightsSidebarItem } from '@spotify/backstage-plugin-insights'

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthOpen,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthOpen,
    marginLeft: 12,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        {/* <SidebarItem icon={SchoolIcon} to='skill-exchange' text='Skill Exchange' /> */}
        <SidebarScrollWrapper>
         <SidebarItem
           icon={MoneyIcon}
           to="cost-insights"
           text="Cost Insights"
         />
        </SidebarScrollWrapper>
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <RBACSidebarItem />
          <SidebarItem icon={TextsmsOutlined} to="feedback" text="Feedback" />
          <SidebarItem icon={PlaylistPlayIcon} to="playlist" text="Playlists" />
          <SidebarItem icon={BuildIcon} to="toolbox" text="ToolBox" />
          <SidebarItem icon={Announcement} to="announcements" text="Announcements" />
          <SidebarItem icon={DesktopWindowsIcon} to="chatgpt" text="ChatGPT" />
          <RequirePermission
            permission={timeSaverPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={Timelapse}
              to="time-saver"
              text="TimeSaver"
            />
          </RequirePermission>
          {/* <SidebarItem icon={DoneAllIcon} to='soundcheck' text='Soundcheck' /> */}
        </SidebarScrollWrapper>
        <SidebarDivider />
        <SidebarGroup label="Management" icon={ <BuildIcon />}>
          <RequirePermission
            permission = { devtoolsAccessPermission }
            errorPage={<></>}
          >
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
            <SidebarItem icon={EmojiObjectsIcon} to="tech-insights" text="Tech insight" />
          </RequirePermission>
          <RequirePermission
            permission={ opsgenieAccessPermission }
            errorPage={<></>}
          >
            <SidebarItem icon={ReportProblemIcon} to="opsgenie" text="Incidents" />
          </RequirePermission>
          <RequirePermission
            permission={ mspAccessPermission }
            errorPage={<></>}
          >
            {/* <InsightsSidebarItem /> */}
            <SidebarItem icon={NextWeekIcon} to="msp-dashboard" text="MSP 1 Click Deployment" />
          </RequirePermission>
        </SidebarGroup>
        <Shortcuts />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
