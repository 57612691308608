import {
    // Build,
    CicdConfiguration,
    CicdDefaults,
    CicdState,
    CicdStatisticsApi,
    FetchBuildsOptions,
    FilterBranchType,
    FilterStatusType,
    TriggerReason,
} from '@backstage-community/plugin-cicd-statistics';
import { IPipelineService } from '../services/bitbucket.service';
import { DurationType } from './types';

/**
 * This class calls the internal bitbuket-backend plugin to retrieve the pipeline statistics of individual repositories
 * The bitbuket-backend plugin handles the auth to the Bitbucket Cloud API
 */
export class BitbucketCicdStatsApi implements CicdStatisticsApi {
    constructor(private readonly cicdDefaults: Partial<CicdDefaults> = {},
        private readonly service: IPipelineService,
        private readonly pageLength: number = 50,
        private readonly getPipelineStagesStats: boolean = false,
        private readonly durationType: DurationType = "duration_in_seconds",
        // This is the max value i.e. at most you can have 50 results per api request: number
    ) {
    }

    public async getConfiguration(): Promise<Partial<CicdConfiguration>> {

        return {
            availableStatuses: [
                'succeeded',
                'failed',
                'enqueued',
                'running',
                'aborted',
                'stalled',
                'expired',
                'unknown',
            ] as const,
            defaults: this.cicdDefaults,
        };
    }

    async fetchBuilds(options: FetchBuildsOptions): Promise<CicdState> {
        const repoName = options.entity.metadata["name"];
        let page = 1;

        let builds: any[] = [];

        // First make an initial request to determine the number of records (size)
        const initialDataset = await this.getPipelineBuildStats(repoName, page, 1);

        // Calculate the number of pages required to query
        const maxPages = Math.ceil((initialDataset.size / this.pageLength));
        const remainingRecords = this.pageLength - ((this.pageLength * maxPages)) % initialDataset.size;

        let requiredPages: number[] = [maxPages];
        const percentage = (remainingRecords / this.pageLength) * 100;
        // If the last page is less than 50% of the page length, then query the previous page as well
        if (percentage < 50) {
            requiredPages = [maxPages -1, maxPages]
        }
        for (const element of requiredPages) {
            const dataset = await this.getPipelineBuildStats(repoName, element, this.pageLength, this.getPipelineStagesStats);
            builds = dataset.builds;
        }

        const cicdData: CicdState = {
            builds: builds,
        };

        return this.getPromise(null, cicdData);
    }

    private async getPipelineBuildStats(repoName: string, page: number, pageLength: number, getStages: boolean = false) {
        const executions = await this.service.getPipelineExecutions(repoName, page, pageLength);
        const size = executions.size;
        const builds = [];
        for (let index = 0; index < executions.values.length; index++) {
            try {
                const element = executions.values[index];
                let bitbucketSteps: any = {};
                if (getStages) {
                    bitbucketSteps = await this.service.getPipelineExecutionDetails(repoName, element.uuid);
                }
                if (!bitbucketSteps.values || !bitbucketSteps.values.length) {
                    bitbucketSteps.values = [];
                }

                const build = {
                    id: element.uuid as string,
                    status: this.mapFilterStatusTypeForPipeline(element?.state?.result?.name ? element?.state?.result?.name : "unknown"),
                    branchType: this.mapFilterBranchType(element.target.ref_name),
                    requestedAt: new Date(element.created_on as Date),
                    duration: this.getDurationInSeconds(element), // duration_in_seconds or build_seconds_used duration: (element.duration_in_seconds * 1000) as number,
                    triggeredBy: this.mapTriggerReason(element.trigger.name),
                    // stages: bitbucketSteps
                    stages: bitbucketSteps.values.map((step: any) => {
                        return {
                            name: step.name,
                            status: this.mapFilterStatusTypeForStep(element?.state?.result?.name ? element?.state?.result?.name : "unknown"),
                            duration: this.getDurationInSeconds(step) // duration_in_seconds or build_seconds_used
                        };
                    })
                };
                builds.push(build);
            } catch {
            }
        }
        return { page: page, size: size, builds: builds };
    }

    private getDurationInSeconds(element: any) {
        return (element[this.durationType as string] * 1000) as number;
    }

    private mapTriggerReason(triggerName: string): TriggerReason {
        /** Triggered by source code management, e.g. a Github hook */
        // 'scm'
        // /** Triggered manually */
        // | 'manual'
        // /** Triggered internally (non-scm, or perhaps after being delayed/enqueued) */
        // | 'internal'
        // /** Triggered for some other reason */
        // | 'other';
        switch (triggerName) {
            case "PUSH":
                return "scm";
            case "MANUAL":
                return "manual";
            default:
                return "other";
        }
    }

    private mapFilterBranchType(branchName: string): FilterBranchType {
        if (branchName.toLowerCase() === "main" || branchName.toLowerCase() === "master") {
            return "master";
        }
        return "branch";
    }

    private mapFilterStatusTypeForPipeline(pipelineStatus: string): FilterStatusType {
        // Pipeline states obtained from: https://community.atlassian.com/t5/Bitbucket-discussions/Bitbucket-API-pipeline-states/td-p/2220677
        // Backstage FilterStatusTypes 'unknown', 'enqueued', 'scheduled', 'running', 'aborted', 'succeeded', 'failed', 'stalled', 'expired'
        switch (pipelineStatus) {
            case "FAILED":
            case "ERROR":
                return "failed";
            case "EXPIRED":
                return "expired";
            case "PAUSED":
                return "stalled";
            case "PENDING":
                return "enqueued";
            case "RUNNING":
            case "IN_PROGRESS":
                return "running";
            case "STOPPED":
                return "aborted";
            case "SUCCESSFUL":
            case "COMPLETED":
                return 'succeeded';
            default:
                return 'unknown';
        }
    }

    private mapFilterStatusTypeForStep(stepStatus: string): FilterStatusType {
        // Pipeline states obtained from: https://community.atlassian.com/t5/Bitbucket-discussions/Bitbucket-API-pipeline-states/td-p/2220677
        // Backstage FilterStatusTypes 'unknown', 'enqueued', 'scheduled', 'running', 'aborted', 'succeeded', 'failed', 'stalled', 'expired'
        switch (stepStatus) {
            case "FAILED":
            case "ERROR":
                return "failed";
            case "EXPIRED":
                return "expired";
            case "PENDING":
            case "READY":
                return "enqueued";
            case "RUNNING":
            case "IN_PROGRESS":
                return "running";
            case "STOPPED":
                return "aborted";
            case "SUCCESSFUL":
            case "COMPLETED":
                return 'succeeded';
            default:
                return 'unknown';
        }
    }

    private getPromise(_: any, res: any): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, 0, res));
    }
}
