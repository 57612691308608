import {
  configApiRef,
  createApiFactory,
  createRoutableExtension,
  createPlugin,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { MspDashboardApiClient, mspDashboardApiRef } from './api';

export const mspDashboardPlugin = createPlugin({
  id: 'msp-dashboard',
  apis: [
    createApiFactory({
      api: mspDashboardApiRef,
      deps: {
        fetchApi: fetchApiRef,
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ fetchApi, configApi }) => new MspDashboardApiClient({ fetchApi, configApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const MspDashboardPage = mspDashboardPlugin.provide(
  createRoutableExtension({
    name: 'MspDashboardPage',
    component: () =>
      import('./components/DashboardComponent').then(m => m.DashboardComponent),
    mountPoint: rootRouteRef,
  }),
);
