import { BitbucketGetPipelinesError } from '../exceptions/exceptions';
import { FetchApi, ConfigApi } from '@backstage/core-plugin-api';

export const BITBUCKET_BASE_URL = 'https://api.bitbucket.org/2.0';

export interface IPipelineService {
  getPipelineExecutions(repositoryName: string, page: number, pageLength: number): Promise<any>;
  getPipelineExecutionDetails(repositoryName: string, pipelineUuid: string): Promise<any>;
}

export class BitbucketPipelineService implements IPipelineService {
  constructor(
    private fetchApi: FetchApi,
    private configApi: ConfigApi
  ) { }

  // GET/repositories/{workspace}/{repo_slug}/pipelines
  // https://developer.atlassian.com/cloud/bitbucket/rest/api-group-pipelines/#api-repositories-workspace-repo-slug-pipelines-get
  // GET /repositories/:repoName/pipelines/:page/:pageLength
  async getPipelineExecutions(repositoryName: string, page: number, pageLength: number): Promise<any> {
    const backendUrl = this.getBackendUrl();
    const apiUrl = `${backendUrl}/repositories/${repositoryName}/pipelines/${page}/${pageLength}`;
    // /repositories/${this.workspace}/${repositoryName}/pipelines?page=${page}&pagelen=${pageLength}
    const response = await this.fetchApi.fetch(apiUrl);
    if (!response.ok) {
      console.log(`Error while querying pipeline stats: ${JSON.stringify(response)}`);
      //TODO: figure out how to put response.json in error
      const responseText = await response.text();
      throw new BitbucketGetPipelinesError(`${response.status} - ${responseText}`);
    }
    const results = await response.json();
    return results;
  }

  // GET/repositories/{workspace}/{repo_slug}/pipelines/{pipeline_uuid}/steps
  // https://developer.atlassian.com/cloud/bitbucket/rest/api-group-pipelines/#api-repositories-workspace-repo-slug-pipelines-pipeline-uuid-steps-get
  // GET /repositories/:repoName/pipelines/:pipelineUuid
  async getPipelineExecutionDetails(repositoryName: string, pipelineUuid: string): Promise<any> {
    const backendUrl = this.getBackendUrl();
    const apiUrl = `${backendUrl}/repositories/${repositoryName}/pipelines/${pipelineUuid}`;
    // /repositories/${this.workspace}/${repositoryName}/pipelines?page=${page}&pagelen=${pageLength}
    const response = await this.fetchApi.fetch(apiUrl);

    if (!response.ok) {
      console.log(`Error while querying pipeline stats: ${JSON.stringify(response)}`);
      //TODO: figure out how to put response.json in error
      throw new BitbucketGetPipelinesError(await response.text());
    }
    const results = await response.json();
    return results;
  }

  private getBackendUrl(): string {
    const baseUrl = this.configApi.getString('backend.baseUrl');
    // The path msp-backend corresponds to the pluginId of the MSP Backend Plugin in plugins/msp-plugin-backend/plugin.ts
    return `${baseUrl}/api/bitbucketPlugin`;
  }
}
