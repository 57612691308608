import { createPermission } from '@backstage/plugin-permission-common'

export const mspAccessPermission = createPermission({
    name: "msp.dashboard.access",
    attributes: {
        action: "read"
    }
});

export const mspPermissions = [
    mspAccessPermission
]