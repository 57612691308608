import React from "react";
import { useEffect, useState } from "react";

/*
  This component was derived from this post: https://stackoverflow.com/questions/20926551/recommended-way-of-making-react-component-div-draggable
  Specifically answered on the Jan 28, 2023 at 17:05 by Anish Raj Pandey
*/

// The children argument on the constructor allows for passing any component as an argument to make it draggable
// This is very useful, since we can apply it to other components, not just the OpcFeedbackComponent button
const Draggable = ({ children}: { children: any }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [xTranslate, setXTranslate] = useState(0);
  const [yTranslate, setYTranslate] = useState(0);
  const [initialMousePosition, setInitialMousePosition] = useState({x: 0, y: 0});

  // Set the method to handle mouse down events. This allows the component to move with the cursor
  const onMouseDown = ({ clientX, clientY }: { clientX: number, clientY: number }) => {
    setInitialMousePosition({ x: clientX, y: clientY });
    setIsDragging(true);
  };

  useEffect(() => {
    // This translates the mouses postion to the components as we move it
    const onMouseMove = (e: any) => {
      setXTranslate(xTranslate + e.clientX - initialMousePosition.x);
      setYTranslate(yTranslate + e.clientY - initialMousePosition.y);
    };
    // While the component is being draggedm we continue to translate its positon
    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
    }
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, [isDragging, initialMousePosition]);

  useEffect(() => {
    // Once the mouse down has been released, set the dragging state to false
    const onMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", onMouseUp);
    return () => window.removeEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <div
      // This will set the styling to continuosly translate the div as the mouseup and mousedown events are being triggered
      style={{ transform: `translate(${xTranslate}px,${yTranslate}px)` }}
      onMouseDown={onMouseDown}
    >
      {" "}
      {children}
    </div>
  );
};

export default Draggable;