import { ChangelogProps } from "@rsc-labs/backstage-changelog-plugin";
import React from 'react';
import { StatusError, StatusOK, StatusPending, StatusRunning, StatusWarning, StatusAborted } from '@backstage/core-components';

enum EChangelogAction {
  Features = 'Features',
  BreakingChanges = '⚠ BREAKING CHANGES',
  Bugs = 'Bug Fixes',
  Perf = 'Performance Improvements',
  Build = 'Build System',
  Ci = 'Continuous Integration'
}

// Parses Changelog.md files of entities and returns icons and content based on each of the change entries
export function conventionalChangelogParser(changeLogContent: string): ChangelogProps[] {
  // Split the changelog file contents by end of line characters
  let splittedLines = changeLogContent.split(/\r?\n/);

  let changelogIndex = -1;
  let action: string | undefined;
  let ActionIcon: any;
  let actionContent: string | undefined;
  let versionContent: string | undefined;
  let actionCounter: number = 0;
  // Use reduce to produce an array of ChangelogProps, which will be displayed on the Changelog card of entities
  const changelogParsed = splittedLines.reduce((resultArray: ChangelogProps[], item: string) => {
    // The top level if statements determine the style of content that will be displayed on the card
    // If the line starts with ##, it indicates the header for the list of changes

    /**
     * Changelog example
      # Changelog
      All notable changes to this project will be documented in this file. See
      [Conventional Commits](https://conventionalcommits.org) for commit guidelines.

      ## [1.1.1](https://bitbucket.org/synthesis_admin/dotnet-backend-standards/compare/v1.1.0...v1.1.1) (2024-06-19)

      ### Features
      * automating version control ([803b972](https://bitbucket.org/synthesis_admin/dotnet-backend-standards/commit/803b9721485aecc1078e5fddbbd2f5c4f238d8f2))

      ### Bug Fixes
      * bug on termplate that is preventing template creation due to version ([b5d055d](https://bitbucket.org/synthesis_admin/dotnet-backend-standards/commit/b5d055d389d47c331b845cc0b0ac338f8486c585))

      ## [1.1.0](https://bitbucket.org/synthesis_admin/dotnet-backend-standards/compare/v1.0.0...v1.1.0) (2024-06-13)
     */

      // Indicates the version label for the changes
    if (item.startsWith('## ')) {
      if (action && actionContent && versionContent) {
        resultArray[changelogIndex].actions.push(
          {
            name: action,
            content: actionContent,
            counter: actionCounter,
            icon: ActionIcon
          }
        )
        resultArray[changelogIndex].versionContent = versionContent;
      }

      action = undefined;
      actionContent = undefined;
      versionContent = undefined;

      changelogIndex++;

      resultArray[changelogIndex] = {
        versionNumber: item,
        actions: [],
        versionContent: undefined
      }
    };

    // Indicates the type of change i.e. Features, Bug Fixes, etc
    if (item.startsWith('### ')) {
      if (action && actionContent) {
        resultArray[changelogIndex].actions.push(
          {
            name: action,
            content: actionContent,
            counter: actionCounter,
            icon: ActionIcon
          }
        )
        action = undefined;
        actionContent = undefined;
        actionCounter = 0;
      }

      ({ action, ActionIcon } = setChangelogAction(item, action, ActionIcon));
    }
    // Indicates the actual commits related to changes
    if (item.startsWith('*')) {
      actionCounter++;
      if (actionContent) {
        actionContent += (item + '\n')
      } else {
        actionContent = item + '\n';
      }
    }
    if (versionContent) {
      versionContent += (item + '\n')
    } else {
      versionContent = item + '\n';
    }
    return resultArray;
  }, [])
  return changelogParsed;
}

// Set the icon based ont the change type
function setChangelogAction(item: string, action: string | undefined, ActionIcon: any) {
  if (item.includes(`### ${EChangelogAction.Features}`)) {
    action = EChangelogAction.Features;
    ActionIcon = () => <StatusOK></StatusOK>;
  }
  if (item.includes(`### ${EChangelogAction.Bugs}`)) {
    action = EChangelogAction.Bugs;
    ActionIcon = () => <StatusRunning></StatusRunning>;
  }
  if (item.includes(`### ${EChangelogAction.BreakingChanges}`)) {
    action = EChangelogAction.BreakingChanges;
    ActionIcon = () => <StatusAborted></StatusAborted>;
  }
  if (item.includes(`### ${EChangelogAction.Perf}`)) {
    action = EChangelogAction.Perf;
    ActionIcon = () => <StatusError></StatusError>;
  }
  if (item.includes(`### ${EChangelogAction.Build}`)) {
    action = EChangelogAction.Build;
    ActionIcon = () => <StatusWarning></StatusWarning>;
  }
  if (item.includes(`### ${EChangelogAction.Ci}`)) {
    action = EChangelogAction.Ci;
    ActionIcon = () => <StatusPending></StatusPending>;
  }
  return { action, ActionIcon };
}
