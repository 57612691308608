import React from 'react'
import { Icon } from "@material-ui/core";
import Terraform from './assets/terraform.svg'

const TerraformIcon = () => (
  <Icon>
      <img src={Terraform} height={'100%'} width={'100%'}/>
  </Icon>
)

export default TerraformIcon;