import { createApiRef } from '@backstage/core-plugin-api';

export const mspDashboardApiRef = createApiRef<MspDashboardApi>({
  id: 'plugin.mspdashboard.service',
});

export interface MspDashboardApi {
  hello(): Promise<string>;
  getRepoDirectoryStructure(mspRepoName: string, branch: string, path: string): Promise<any>;
}
